import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Img from 'gatsby-image';

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const Bold = ({ children }) => <span className="bold">{children}</span>
const Text = ({ children }) => <p className="align-center">{children}</p>

const IndexPage = () => {
  const allData = useStaticQuery(graphql`
    query MyContentfulQuery {
      allContentfulPosts {
        edges {
          node {
            id
            title
            updatedAt
            postImage {
              title
              alt: description
              localFile {
                publicURL
                extension
                childImageSharp {
                  fluid(maxWidth: 2000, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            content {
              json
            }
          }
        }
      }
    }
  `)

  const {
    allContentfulPosts: { edges: posts },
  } = allData

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    },
  }

  // documentToReactComponents(node.bodyRichText.json, options)

  return (
    <Layout>
      <SEO title="Home" />
      <h1>Hi people</h1>
      <p>Welcome to your new Gatsby site.</p>
      <p>Now go build something great.</p>
      <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
        <Image />
      </div>
      {posts.map(post => (
        <div key={post.node.id}>
          <Img fluid={post.node.postImage.localFile.childImageSharp.fluid} />
          {post.node.title} -{" "}
          {documentToReactComponents(post.node.content.json, options)}
        </div>
      ))}
    </Layout>
  )
}

export default IndexPage
